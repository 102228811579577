export const countriesList = [
	{
		name_en: "Afghanistan",
		alpha2: "AF",
		alpha3: "AFG",
		numeric: "004",
	},
	{
		name_en: "Åland Islands",
		alpha2: "AX",
		alpha3: "ALA",
		numeric: "248",
	},
	{
		name_en: "Albania",
		alpha2: "AL",
		alpha3: "ALB",
		numeric: "008",
	},
	{
		name_en: "Algeria",
		alpha2: "DZ",
		alpha3: "DZA",
		numeric: "012",
	},
	{
		name_en: "American Samoa",
		alpha2: "AS",
		alpha3: "ASM",
		numeric: "016",
	},
	{
		name_en: "Andorra",
		alpha2: "AD",
		alpha3: "AND",
		numeric: "020",
	},
	{
		name_en: "Angola",
		alpha2: "AO",
		alpha3: "AGO",
		numeric: "024",
	},
	{
		name_en: "Anguilla",
		alpha2: "AI",
		alpha3: "AIA",
		numeric: "660",
	},
	{
		name_en: "Antarctica",
		alpha2: "AQ",
		alpha3: "ATA",
		numeric: "010",
	},
	{
		name_en: "Antigua and Barbuda",
		alpha2: "AG",
		alpha3: "ATG",
		numeric: "028",
	},
	{
		name_en: "Argentina",
		alpha2: "AR",
		alpha3: "ARG",
		numeric: "032",
	},
	{
		name_en: "Armenia",
		alpha2: "AM",
		alpha3: "ARM",
		numeric: "051",
	},
	{
		name_en: "Aruba",
		alpha2: "AW",
		alpha3: "ABW",
		numeric: "533",
	},
	{
		name_en: "Australia",
		alpha2: "AU",
		alpha3: "AUS",
		numeric: "036",
	},
	{
		name_en: "Austria",
		alpha2: "AT",
		alpha3: "AUT",
		numeric: "040",
	},
	{
		name_en: "Azerbaijan",
		alpha2: "AZ",
		alpha3: "AZE",
		numeric: "031",
	},
	{
		name_en: "Bahamas (the)",
		alpha2: "BS",
		alpha3: "BHS",
		numeric: "044",
	},
	{
		name_en: "Bahrain",
		alpha2: "BH",
		alpha3: "BHR",
		numeric: "048",
	},
	{
		name_en: "Bangladesh",
		alpha2: "BD",
		alpha3: "BGD",
		numeric: "050",
	},
	{
		name_en: "Barbados",
		alpha2: "BB",
		alpha3: "BRB",
		numeric: "052",
	},
	{
		name_en: "Belarus",
		alpha2: "BY",
		alpha3: "BLR",
		numeric: "112",
	},
	{
		name_en: "Belgium",
		alpha2: "BE",
		alpha3: "BEL",
		numeric: "056",
	},
	{
		name_en: "Belize",
		alpha2: "BZ",
		alpha3: "BLZ",
		numeric: "084",
	},
	{
		name_en: "Benin",
		alpha2: "BJ",
		alpha3: "BEN",
		numeric: "204",
	},
	{
		name_en: "Bermuda",
		alpha2: "BM",
		alpha3: "BMU",
		numeric: "060",
	},
	{
		name_en: "Bhutan",
		alpha2: "BT",
		alpha3: "BTN",
		numeric: "064",
	},
	{
		name_en: "Bolivia (Plurinational State of)",
		alpha2: "BO",
		alpha3: "BOL",
		numeric: "068",
	},
	{
		name_en: "Bonaire, Sint Eustatius and Saba",
		alpha2: "BQ",
		alpha3: "BES",
		numeric: "535",
	},
	{
		name_en: "Bosnia and Herzegovina",
		alpha2: "BA",
		alpha3: "BIH",
		numeric: "070",
	},
	{
		name_en: "Botswana",
		alpha2: "BW",
		alpha3: "BWA",
		numeric: "072",
	},
	{
		name_en: "Bouvet Island",
		alpha2: "BV",
		alpha3: "BVT",
		numeric: "074",
	},
	{
		name_en: "Brazil",
		alpha2: "BR",
		alpha3: "BRA",
		numeric: "076",
	},
	{
		name_en: "British Indian Ocean Territory (the)",
		alpha2: "IO",
		alpha3: "IOT",
		numeric: "086",
	},
	{
		name_en: "Brunei Darussalam",
		alpha2: "BN",
		alpha3: "BRN",
		numeric: "096",
	},
	{
		name_en: "Bulgaria",
		alpha2: "BG",
		alpha3: "BGR",
		numeric: "100",
	},
	{
		name_en: "Burkina Faso",
		alpha2: "BF",
		alpha3: "BFA",
		numeric: "854",
	},
	{
		name_en: "Burundi",
		alpha2: "BI",
		alpha3: "BDI",
		numeric: "108",
	},
	{
		name_en: "Cabo Verde",
		alpha2: "CV",
		alpha3: "CPV",
		numeric: "132",
	},
	{
		name_en: "Cambodia",
		alpha2: "KH",
		alpha3: "KHM",
		numeric: "116",
	},
	{
		name_en: "Cameroon",
		alpha2: "CM",
		alpha3: "CMR",
		numeric: "120",
	},
	{
		name_en: "Canada",
		alpha2: "CA",
		alpha3: "CAN",
		numeric: "124",
	},
	{
		name_en: "Cayman Islands (the)",
		alpha2: "KY",
		alpha3: "CYM",
		numeric: "136",
	},
	{
		name_en: "Central African Republic (the)",
		alpha2: "CF",
		alpha3: "CAF",
		numeric: "140",
	},
	{
		name_en: "Chad",
		alpha2: "TD",
		alpha3: "TCD",
		numeric: "148",
	},
	{
		name_en: "Chile",
		alpha2: "CL",
		alpha3: "CHL",
		numeric: "152",
	},
	{
		name_en: "China",
		alpha2: "CN",
		alpha3: "CHN",
		numeric: "156",
	},
	{
		name_en: "Christmas Island",
		alpha2: "CX",
		alpha3: "CXR",
		numeric: "162",
	},
	{
		name_en: "Cocos (Keeling) Islands (the)",
		alpha2: "CC",
		alpha3: "CCK",
		numeric: "166",
	},
	{
		name_en: "Colombia",
		alpha2: "CO",
		alpha3: "COL",
		numeric: "170",
	},
	{
		name_en: "Comoros (the)",
		alpha2: "KM",
		alpha3: "COM",
		numeric: "174",
	},
	{
		name_en: "Congo (the Democratic Republic of the)",
		alpha2: "CD",
		alpha3: "COD",
		numeric: "180",
	},
	{
		name_en: "Congo (the)",
		alpha2: "CG",
		alpha3: "COG",
		numeric: "178",
	},
	{
		name_en: "Cook Islands (the)",
		alpha2: "CK",
		alpha3: "COK",
		numeric: "184",
	},
	{
		name_en: "Costa Rica",
		alpha2: "CR",
		alpha3: "CRI",
		numeric: "188",
	},
	{
		name_en: "Côte d'Ivoire",
		alpha2: "CI",
		alpha3: "CIV",
		numeric: "384",
	},
	{
		name_en: "Croatia",
		alpha2: "HR",
		alpha3: "HRV",
		numeric: "191",
	},
	{
		name_en: "Cuba",
		alpha2: "CU",
		alpha3: "CUB",
		numeric: "192",
	},
	{
		name_en: "Curaçao",
		alpha2: "CW",
		alpha3: "CUW",
		numeric: "531",
	},
	{
		name_en: "Cyprus",
		alpha2: "CY",
		alpha3: "CYP",
		numeric: "196",
	},
	{
		name_en: "Czechia",
		alpha2: "CZ",
		alpha3: "CZE",
		numeric: "203",
	},
	{
		name_en: "Denmark",
		alpha2: "DK",
		alpha3: "DNK",
		numeric: "208",
	},
	{
		name_en: "Djibouti",
		alpha2: "DJ",
		alpha3: "DJI",
		numeric: "262",
	},
	{
		name_en: "Dominica",
		alpha2: "DM",
		alpha3: "DMA",
		numeric: "212",
	},
	{
		name_en: "Dominican Republic (the)",
		alpha2: "DO",
		alpha3: "DOM",
		numeric: "214",
	},
	{
		name_en: "Ecuador",
		alpha2: "EC",
		alpha3: "ECU",
		numeric: "218",
	},
	{
		name_en: "Egypt",
		alpha2: "EG",
		alpha3: "EGY",
		numeric: "818",
	},
	{
		name_en: "El Salvador",
		alpha2: "SV",
		alpha3: "SLV",
		numeric: "222",
	},
	{
		name_en: "Equatorial Guinea",
		alpha2: "GQ",
		alpha3: "GNQ",
		numeric: "226",
	},
	{
		name_en: "Eritrea",
		alpha2: "ER",
		alpha3: "ERI",
		numeric: "232",
	},
	{
		name_en: "Estonia",
		alpha2: "EE",
		alpha3: "EST",
		numeric: "233",
	},
	{
		name_en: "Eswatini",
		alpha2: "SZ",
		alpha3: "SWZ",
		numeric: "748",
	},
	{
		name_en: "Ethiopia",
		alpha2: "ET",
		alpha3: "ETH",
		numeric: "231",
	},
	{
		name_en: "Falkland Islands (the) [Malvinas]",
		alpha2: "FK",
		alpha3: "FLK",
		numeric: "238",
	},
	{
		name_en: "Faroe Islands (the)",
		alpha2: "FO",
		alpha3: "FRO",
		numeric: "234",
	},
	{
		name_en: "Fiji",
		alpha2: "FJ",
		alpha3: "FJI",
		numeric: "242",
	},
	{
		name_en: "Finland",
		alpha2: "FI",
		alpha3: "FIN",
		numeric: "246",
	},
	{
		name_en: "France",
		alpha2: "FR",
		alpha3: "FRA",
		numeric: "250",
	},
	{
		name_en: "French Guiana",
		alpha2: "GF",
		alpha3: "GUF",
		numeric: "254",
	},
	{
		name_en: "French Polynesia",
		alpha2: "PF",
		alpha3: "PYF",
		numeric: "258",
	},
	{
		name_en: "French Southern Territories (the)",
		alpha2: "TF",
		alpha3: "ATF",
		numeric: "260",
	},
	{
		name_en: "Gabon",
		alpha2: "GA",
		alpha3: "GAB",
		numeric: "266",
	},
	{
		name_en: "Gambia (the)",
		alpha2: "GM",
		alpha3: "GMB",
		numeric: "270",
	},
	{
		name_en: "Georgia",
		alpha2: "GE",
		alpha3: "GEO",
		numeric: "268",
	},
	{
		name_en: "Germany",
		alpha2: "DE",
		alpha3: "DEU",
		numeric: "276",
	},
	{
		name_en: "Ghana",
		alpha2: "GH",
		alpha3: "GHA",
		numeric: "288",
	},
	{
		name_en: "Gibraltar",
		alpha2: "GI",
		alpha3: "GIB",
		numeric: "292",
	},
	{
		name_en: "Greece",
		alpha2: "GR",
		alpha3: "GRC",
		numeric: "300",
	},
	{
		name_en: "Greenland",
		alpha2: "GL",
		alpha3: "GRL",
		numeric: "304",
	},
	{
		name_en: "Grenada",
		alpha2: "GD",
		alpha3: "GRD",
		numeric: "308",
	},
	{
		name_en: "Guadeloupe",
		alpha2: "GP",
		alpha3: "GLP",
		numeric: "312",
	},
	{
		name_en: "Guam",
		alpha2: "GU",
		alpha3: "GUM",
		numeric: "316",
	},
	{
		name_en: "Guatemala",
		alpha2: "GT",
		alpha3: "GTM",
		numeric: "320",
	},
	{
		name_en: "Guernsey",
		alpha2: "GG",
		alpha3: "GGY",
		numeric: "831",
	},
	{
		name_en: "Guinea",
		alpha2: "GN",
		alpha3: "GIN",
		numeric: "324",
	},
	{
		name_en: "Guinea-Bissau",
		alpha2: "GW",
		alpha3: "GNB",
		numeric: "624",
	},
	{
		name_en: "Guyana",
		alpha2: "GY",
		alpha3: "GUY",
		numeric: "328",
	},
	{
		name_en: "Haiti",
		alpha2: "HT",
		alpha3: "HTI",
		numeric: "332",
	},
	{
		name_en: "Heard Island and McDonald Islands",
		alpha2: "HM",
		alpha3: "HMD",
		numeric: "334",
	},
	{
		name_en: "Holy See (the)",
		alpha2: "VA",
		alpha3: "VAT",
		numeric: "336",
	},
	{
		name_en: "Honduras",
		alpha2: "HN",
		alpha3: "HND",
		numeric: "340",
	},
	{
		name_en: "Hong Kong",
		alpha2: "HK",
		alpha3: "HKG",
		numeric: "344",
	},
	{
		name_en: "Hungary",
		alpha2: "HU",
		alpha3: "HUN",
		numeric: "348",
	},
	{
		name_en: "Iceland",
		alpha2: "IS",
		alpha3: "ISL",
		numeric: "352",
	},
	{
		name_en: "India",
		alpha2: "IN",
		alpha3: "IND",
		numeric: "356",
	},
	{
		name_en: "Indonesia",
		alpha2: "ID",
		alpha3: "IDN",
		numeric: "360",
	},
	{
		name_en: "Iran (Islamic Republic of)",
		alpha2: "IR",
		alpha3: "IRN",
		numeric: "364",
	},
	{
		name_en: "Iraq",
		alpha2: "IQ",
		alpha3: "IRQ",
		numeric: "368",
	},
	{
		name_en: "Ireland",
		alpha2: "IE",
		alpha3: "IRL",
		numeric: "372",
	},
	{
		name_en: "Isle of Man",
		alpha2: "IM",
		alpha3: "IMN",
		numeric: "833",
	},
	{
		name_en: "Israel",
		alpha2: "IL",
		alpha3: "ISR",
		numeric: "376",
	},
	{
		name_en: "Italy",
		alpha2: "IT",
		alpha3: "ITA",
		numeric: "380",
	},
	{
		name_en: "Jamaica",
		alpha2: "JM",
		alpha3: "JAM",
		numeric: "388",
	},
	{
		name_en: "Japan",
		alpha2: "JP",
		alpha3: "JPN",
		numeric: "392",
	},
	{
		name_en: "Jersey",
		alpha2: "JE",
		alpha3: "JEY",
		numeric: "832",
	},
	{
		name_en: "Jordan",
		alpha2: "JO",
		alpha3: "JOR",
		numeric: "400",
	},
	{
		name_en: "Kazakhstan",
		alpha2: "KZ",
		alpha3: "KAZ",
		numeric: "398",
	},
	{
		name_en: "Kenya",
		alpha2: "KE",
		alpha3: "KEN",
		numeric: "404",
	},
	{
		name_en: "Kiribati",
		alpha2: "KI",
		alpha3: "KIR",
		numeric: "296",
	},
	{
		name_en: "Korea (the Democratic People's Republic of)",
		alpha2: "KP",
		alpha3: "PRK",
		numeric: "408",
	},
	{
		name_en: "Korea (the Republic of)",
		alpha2: "KR",
		alpha3: "KOR",
		numeric: "410",
	},
	{
		name_en: "Kuwait",
		alpha2: "KW",
		alpha3: "KWT",
		numeric: "414",
	},
	{
		name_en: "Kyrgyzstan",
		alpha2: "KG",
		alpha3: "KGZ",
		numeric: "417",
	},
	{
		name_en: "Lao People's Democratic Republic (the)",
		alpha2: "LA",
		alpha3: "LAO",
		numeric: "418",
	},
	{
		name_en: "Latvia",
		alpha2: "LV",
		alpha3: "LVA",
		numeric: "428",
	},
	{
		name_en: "Lebanon",
		alpha2: "LB",
		alpha3: "LBN",
		numeric: "422",
	},
	{
		name_en: "Lesotho",
		alpha2: "LS",
		alpha3: "LSO",
		numeric: "426",
	},
	{
		name_en: "Liberia",
		alpha2: "LR",
		alpha3: "LBR",
		numeric: "430",
	},
	{
		name_en: "Libya",
		alpha2: "LY",
		alpha3: "LBY",
		numeric: "434",
	},
	{
		name_en: "Liechtenstein",
		alpha2: "LI",
		alpha3: "LIE",
		numeric: "438",
	},
	{
		name_en: "Lithuania",
		alpha2: "LT",
		alpha3: "LTU",
		numeric: "440",
	},
	{
		name_en: "Luxembourg",
		alpha2: "LU",
		alpha3: "LUX",
		numeric: "442",
	},
	{
		name_en: "Macao",
		alpha2: "MO",
		alpha3: "MAC",
		numeric: "446",
	},
	{
		name_en: "Republic of North Macedonia",
		alpha2: "MK",
		alpha3: "MKD",
		numeric: "807",
	},
	{
		name_en: "Madagascar",
		alpha2: "MG",
		alpha3: "MDG",
		numeric: "450",
	},
	{
		name_en: "Malawi",
		alpha2: "MW",
		alpha3: "MWI",
		numeric: "454",
	},
	{
		name_en: "Malaysia",
		alpha2: "MY",
		alpha3: "MYS",
		numeric: "458",
	},
	{
		name_en: "Maldives",
		alpha2: "MV",
		alpha3: "MDV",
		numeric: "462",
	},
	{
		name_en: "Mali",
		alpha2: "ML",
		alpha3: "MLI",
		numeric: "466",
	},
	{
		name_en: "Malta",
		alpha2: "MT",
		alpha3: "MLT",
		numeric: "470",
	},
	{
		name_en: "Marshall Islands (the)",
		alpha2: "MH",
		alpha3: "MHL",
		numeric: "584",
	},
	{
		name_en: "Martinique",
		alpha2: "MQ",
		alpha3: "MTQ",
		numeric: "474",
	},
	{
		name_en: "Mauritania",
		alpha2: "MR",
		alpha3: "MRT",
		numeric: "478",
	},
	{
		name_en: "Mauritius",
		alpha2: "MU",
		alpha3: "MUS",
		numeric: "480",
	},
	{
		name_en: "Mayotte",
		alpha2: "YT",
		alpha3: "MYT",
		numeric: "175",
	},
	{
		name_en: "Mexico",
		alpha2: "MX",
		alpha3: "MEX",
		numeric: "484",
	},
	{
		name_en: "Micronesia (Federated States of)",
		alpha2: "FM",
		alpha3: "FSM",
		numeric: "583",
	},
	{
		name_en: "Moldova (the Republic of)",
		alpha2: "MD",
		alpha3: "MDA",
		numeric: "498",
	},
	{
		name_en: "Monaco",
		alpha2: "MC",
		alpha3: "MCO",
		numeric: "492",
	},
	{
		name_en: "Mongolia",
		alpha2: "MN",
		alpha3: "MNG",
		numeric: "496",
	},
	{
		name_en: "Montenegro",
		alpha2: "ME",
		alpha3: "MNE",
		numeric: "499",
	},
	{
		name_en: "Montserrat",
		alpha2: "MS",
		alpha3: "MSR",
		numeric: "500",
	},
	{
		name_en: "Morocco",
		alpha2: "MA",
		alpha3: "MAR",
		numeric: "504",
	},
	{
		name_en: "Mozambique",
		alpha2: "MZ",
		alpha3: "MOZ",
		numeric: "508",
	},
	{
		name_en: "Myanmar",
		alpha2: "MM",
		alpha3: "MMR",
		numeric: "104",
	},
	{
		name_en: "Namibia",
		alpha2: "NA",
		alpha3: "NAM",
		numeric: "516",
	},
	{
		name_en: "Nauru",
		alpha2: "NR",
		alpha3: "NRU",
		numeric: "520",
	},
	{
		name_en: "Nepal",
		alpha2: "NP",
		alpha3: "NPL",
		numeric: "524",
	},
	{
		name_en: "Netherlands (the)",
		alpha2: "NL",
		alpha3: "NLD",
		numeric: "528",
	},
	{
		name_en: "New Caledonia",
		alpha2: "NC",
		alpha3: "NCL",
		numeric: "540",
	},
	{
		name_en: "New Zealand",
		alpha2: "NZ",
		alpha3: "NZL",
		numeric: "554",
	},
	{
		name_en: "Nicaragua",
		alpha2: "NI",
		alpha3: "NIC",
		numeric: "558",
	},
	{
		name_en: "Niger (the)",
		alpha2: "NE",
		alpha3: "NER",
		numeric: "562",
	},
	{
		name_en: "Nigeria",
		alpha2: "NG",
		alpha3: "NGA",
		numeric: "566",
	},
	{
		name_en: "Niue",
		alpha2: "NU",
		alpha3: "NIU",
		numeric: "570",
	},
	{
		name_en: "Norfolk Island",
		alpha2: "NF",
		alpha3: "NFK",
		numeric: "574",
	},
	{
		name_en: "Northern Mariana Islands (the)",
		alpha2: "MP",
		alpha3: "MNP",
		numeric: "580",
	},
	{
		name_en: "Norway",
		alpha2: "NO",
		alpha3: "NOR",
		numeric: "578",
	},
	{
		name_en: "Oman",
		alpha2: "OM",
		alpha3: "OMN",
		numeric: "512",
	},
	{
		name_en: "Pakistan",
		alpha2: "PK",
		alpha3: "PAK",
		numeric: "586",
	},
	{
		name_en: "Palau",
		alpha2: "PW",
		alpha3: "PLW",
		numeric: "585",
	},
	{
		name_en: "Palestine, State of",
		alpha2: "PS",
		alpha3: "PSE",
		numeric: "275",
	},
	{
		name_en: "Panama",
		alpha2: "PA",
		alpha3: "PAN",
		numeric: "591",
	},
	{
		name_en: "Papua New Guinea",
		alpha2: "PG",
		alpha3: "PNG",
		numeric: "598",
	},
	{
		name_en: "Paraguay",
		alpha2: "PY",
		alpha3: "PRY",
		numeric: "600",
	},
	{
		name_en: "Peru",
		alpha2: "PE",
		alpha3: "PER",
		numeric: "604",
	},
	{
		name_en: "Philippines (the)",
		alpha2: "PH",
		alpha3: "PHL",
		numeric: "608",
	},
	{
		name_en: "Pitcairn",
		alpha2: "PN",
		alpha3: "PCN",
		numeric: "612",
	},
	{
		name_en: "Poland",
		alpha2: "PL",
		alpha3: "POL",
		numeric: "616",
	},
	{
		name_en: "Portugal",
		alpha2: "PT",
		alpha3: "PRT",
		numeric: "620",
	},
	{
		name_en: "Puerto Rico",
		alpha2: "PR",
		alpha3: "PRI",
		numeric: "630",
	},
	{
		name_en: "Qatar",
		alpha2: "QA",
		alpha3: "QAT",
		numeric: "634",
	},
	{
		name_en: "Réunion",
		alpha2: "RE",
		alpha3: "REU",
		numeric: "638",
	},
	{
		name_en: "Romania",
		alpha2: "RO",
		alpha3: "ROU",
		numeric: "642",
	},
	{
		name_en: "Russian Federation (the)",
		alpha2: "RU",
		alpha3: "RUS",
		numeric: "643",
	},
	{
		name_en: "Rwanda",
		alpha2: "RW",
		alpha3: "RWA",
		numeric: "646",
	},
	{
		name_en: "Saint Barthélemy",
		alpha2: "BL",
		alpha3: "BLM",
		numeric: "652",
	},
	{
		name_en: "Saint Helena, Ascension and Tristan da Cunha",
		alpha2: "SH",
		alpha3: "SHN",
		numeric: "654",
	},
	{
		name_en: "Saint Kitts and Nevis",
		alpha2: "KN",
		alpha3: "KNA",
		numeric: "659",
	},
	{
		name_en: "Saint Lucia",
		alpha2: "LC",
		alpha3: "LCA",
		numeric: "662",
	},
	{
		name_en: "Saint Martin (French part)",
		alpha2: "MF",
		alpha3: "MAF",
		numeric: "663",
	},
	{
		name_en: "Saint Pierre and Miquelon",
		alpha2: "PM",
		alpha3: "SPM",
		numeric: "666",
	},
	{
		name_en: "Saint Vincent and the Grenadines",
		alpha2: "VC",
		alpha3: "VCT",
		numeric: "670",
	},
	{
		name_en: "Samoa",
		alpha2: "WS",
		alpha3: "WSM",
		numeric: "882",
	},
	{
		name_en: "San Marino",
		alpha2: "SM",
		alpha3: "SMR",
		numeric: "674",
	},
	{
		name_en: "Sao Tome and Principe",
		alpha2: "ST",
		alpha3: "STP",
		numeric: "678",
	},
	{
		name_en: "Saudi Arabia",
		alpha2: "SA",
		alpha3: "SAU",
		numeric: "682",
	},
	{
		name_en: "Senegal",
		alpha2: "SN",
		alpha3: "SEN",
		numeric: "686",
	},
	{
		name_en: "Serbia",
		alpha2: "RS",
		alpha3: "SRB",
		numeric: "688",
	},
	{
		name_en: "Seychelles",
		alpha2: "SC",
		alpha3: "SYC",
		numeric: "690",
	},
	{
		name_en: "Sierra Leone",
		alpha2: "SL",
		alpha3: "SLE",
		numeric: "694",
	},
	{
		name_en: "Singapore",
		alpha2: "SG",
		alpha3: "SGP",
		numeric: "702",
	},
	{
		name_en: "Sint Maarten (Dutch part)",
		alpha2: "SX",
		alpha3: "SXM",
		numeric: "534",
	},
	{
		name_en: "Slovakia",
		alpha2: "SK",
		alpha3: "SVK",
		numeric: "703",
	},
	{
		name_en: "Slovenia",
		alpha2: "SI",
		alpha3: "SVN",
		numeric: "705",
	},
	{
		name_en: "Solomon Islands",
		alpha2: "SB",
		alpha3: "SLB",
		numeric: "090",
	},
	{
		name_en: "Somalia",
		alpha2: "SO",
		alpha3: "SOM",
		numeric: "706",
	},
	{
		name_en: "South Africa",
		alpha2: "ZA",
		alpha3: "ZAF",
		numeric: "710",
	},
	{
		name_en: "South Georgia and the South Sandwich Islands",
		alpha2: "GS",
		alpha3: "SGS",
		numeric: "239",
	},
	{
		name_en: "South Sudan",
		alpha2: "SS",
		alpha3: "SSD",
		numeric: "728",
	},
	{
		name_en: "Spain",
		alpha2: "ES",
		alpha3: "ESP",
		numeric: "724",
	},
	{
		name_en: "Sri Lanka",
		alpha2: "LK",
		alpha3: "LKA",
		numeric: "144",
	},
	{
		name_en: "Sudan (the)",
		alpha2: "SD",
		alpha3: "SDN",
		numeric: "729",
	},
	{
		name_en: "Suriname",
		alpha2: "SR",
		alpha3: "SUR",
		numeric: "740",
	},
	{
		name_en: "Svalbard and Jan Mayen",
		alpha2: "SJ",
		alpha3: "SJM",
		numeric: "744",
	},
	{
		name_en: "Sweden",
		alpha2: "SE",
		alpha3: " SWE",
		numeric: "752",
	},
	{
		name_en: "Switzerland",
		alpha2: "CH",
		alpha3: "CHE",
		numeric: "756",
	},
	{
		name_en: "Syrian Arab Republic",
		alpha2: "SY",
		alpha3: "SYR",
		numeric: "760",
	},
	{
		name_en: "Taiwan (Province of China)",
		alpha2: "TW",
		alpha3: "TWN",
		numeric: "158",
	},
	{
		name_en: "Tajikistan",
		alpha2: "TJ",
		alpha3: "TJK",
		numeric: "762",
	},
	{
		name_en: "Tanzania, United Republic of",
		alpha2: "TZ",
		alpha3: "TZA",
		numeric: "834",
	},
	{
		name_en: "Thailand",
		alpha2: "TH",
		alpha3: "THA",
		numeric: "764",
	},
	{
		name_en: "Timor-Leste",
		alpha2: "TL",
		alpha3: "TLS",
		numeric: "626",
	},
	{
		name_en: "Togo",
		alpha2: "TG",
		alpha3: "TGO",
		numeric: "768",
	},
	{
		name_en: "Tokelau",
		alpha2: "TK",
		alpha3: "TKL",
		numeric: "772",
	},
	{
		name_en: "Tonga",
		alpha2: "TO",
		alpha3: "TON",
		numeric: "776",
	},
	{
		name_en: "Trinidad and Tobago",
		alpha2: "TT",
		alpha3: "TTO",
		numeric: "780",
	},
	{
		name_en: "Tunisia",
		alpha2: "TN",
		alpha3: "TUN",
		numeric: "788",
	},
	{
		name_en: "Turkey",
		alpha2: "TR",
		alpha3: "TUR",
		numeric: "792",
	},
	{
		name_en: "Turkmenistan",
		alpha2: "TM",
		alpha3: "TKM",
		numeric: "795",
	},
	{
		name_en: "Turks and Caicos Islands (the)",
		alpha2: "TC",
		alpha3: "TCA",
		numeric: "796",
	},
	{
		name_en: "Tuvalu",
		alpha2: "TV",
		alpha3: "TUV",
		numeric: "798",
	},
	{
		name_en: "Uganda",
		alpha2: "UG",
		alpha3: "UGA",
		numeric: "800",
	},
	{
		name_en: "Ukraine",
		alpha2: "UA",
		alpha3: "UKR",
		numeric: "804",
	},
	{
		name_en: "United Arab Emirates",
		alpha2: "AE",
		alpha3: "ARE",
		numeric: "784",
	},
	{
		name_en: "United Kingdom",
		alpha2: "GB",
		alpha3: "GBR",
		numeric: "826",
	},
	{
		name_en: "United States Minor Outlying Islands",
		alpha2: "UM",
		alpha3: "UMI",
		numeric: "581",
	},
	{
		name_en: "United States of America",
		alpha2: "US",
		alpha3: "USA",
		numeric: "840",
	},
	{
		name_en: "Uruguay",
		alpha2: "UY",
		alpha3: "URY",
		numeric: "858",
	},
	{
		name_en: "Uzbekistan",
		alpha2: "UZ",
		alpha3: "UZB",
		numeric: "860",
	},
	{
		name_en: "Vanuatu",
		alpha2: "VU",
		alpha3: "VUT",
		numeric: "548",
	},
	{
		name_en: "Venezuela (Bolivarian Republic of)",
		alpha2: "VE",
		alpha3: "VEN",
		numeric: "862",
	},
	{
		name_en: "Viet Nam",
		alpha2: "VN",
		alpha3: "VNM",
		numeric: "704",
	},
	{
		name_en: "Virgin Islands (British)",
		alpha2: "VG",
		alpha3: "VGB",
		numeric: "092",
	},
	{
		name_en: "Virgin Islands (U.S.)",
		alpha2: "VI",
		alpha3: "VIR",
		numeric: "850",
	},
	{
		name_en: "Wallis and Futuna",
		alpha2: "WF",
		alpha3: "WLF",
		numeric: "876",
	},
	{
		name_en: "Western Sahara",
		alpha2: "EH",
		alpha3: "ESH",
		numeric: "732",
	},
	{
		name_en: "Yemen",
		alpha2: "YE",
		alpha3: "YEM",
		numeric: "887",
	},
	{
		name_en: "Zambia",
		alpha2: "ZM",
		alpha3: "ZMB",
		numeric: "894",
	},
	{
		name_en: "Zimbabwe",
		alpha2: "ZW",
		alpha3: "ZWE",
		numeric: "716",
	},
];
